@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Medium.woff2') format('woff2');
    font-weight: bold;
}

.input,
app-sign-up .form-control,
.field .form-control {
    font-family: 'Lato', sans-serif;
    background-color: #fff;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-button {
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: #2c5d7d;
    color: white;
    padding: 1rem;
    font-weight: normal;
    box-shadow: none;

    &:hover {
        background-color: darken(#2c5d7d, 10%);
        color: white;
    }

    &:disabled {
        background-color: lighten(#2c5d7d, 20%);
    }

    &.btn-primary {
        color: rgb(27, 27, 27);
    }
}

ion-button:not(.in-toolbar),
ion-button:not(.in-toolbar):not([color]):not([fill]) {
    --background: #2c5d7d;
    --background-activated: darken(#2c5d7d, 10%);
    --border-radius: 8px;
    --color: white;
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;
    --box-shadow: none;

    &.danger {
        --background: #ed1c24;
        --background-activated: darken(#ed1c24, 10%);
    }
}

ion-toolbar {
    --background: #eaf2e2;
    --min-height: 30px;
    --max-height: 30px;
    --padding-top: 0;
    --padding-bottom: 0;

    --color: #2e5e7e;

    ion-back-button,
    ion-button,
    ion-icon {
        --color: #2e5e7e;
        font-size: 10px;
        height: 30px;
        width: 30px;
    }
}

.ion-toolbar-with-padding {
    --padding-start: 10px;
    --padding-end: 10px;
}

ion-popover.full {
    --width: 90%;
}
