/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";
:root {
    --text-primary: #2E5D7D;
    --ofc-green: #73AA42;
    --page-background: #e9f1e0;
    --fundamental-of-the-week: #47a1ae;
    --footer-icon-bkgd-1: #2e5d7d;
    --footer-icon-bkgd-2: #73aa42;
    --primary-buttons: #2e5d7d;
    --primary-buttons-shade: #204056;
    --primary-buttons-tint: #265e83;
    --secondary-buttons: #d3e4c5;
    --secondary-buttons-shade: #a4b398;
    --primary-buttons-tint: #c8e1b3;
    --buttons-hover: #47a1ae;
    --button-text: #ffffff;
}

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

app-sign-up div.errortext {
    position: relative !important; // prevents validation error messages from rendering on top of other elements in mobile view
}

app-activate-account div.errortext {
    position: relative !important; // prevents validation error messages from rendering on top of other elements in mobile view
}

app-sign-up .form-control {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.mobile-column {
    display: grid;
    text-align: center;
    justify-items: center;

    mt-dynamic-form {
        width: 78%;
        display: block;
    }
}

label {
    font-size: 0.9em;
    display: inline-block;
}

@media (min-width: 200px) {
    .mobile-header {
        background-color: white;
        height: 17%;
        width: 100%;
        padding: 3%;
    }
}

@media (min-width: 900px) {
    /* for desktop screens */
    .mobile-header {
        background-color: white;
        width: 100%;
        padding: 3%;
    }

    .img-responsive {
        height: 100px !important;
    }
}

.btn-primary {
    background-color: #2e5d7d;
}

@MainOFCColor: #d2e4c3;
@MainOFCTextColor: black;

.content-wrapper {
    background-color: lighten(#73aa42, 50%) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: @MainOFCTextColor !important;
}
// Nav styling

.skin-blue .main-header .logo,
.skin-blue .main-header .logo:hover,
.skin-blue .main-header .navbar {
    background-color: @MainOFCColor;
    color: @MainOFCTextColor !important;
}

.nav > li > a,
.navbar-nav.nav-header-button,
.navbar-nav.submit-help {
    color: @MainOFCTextColor !important;
}

.skin-blue .left-side,
.skin-blue .main-sidebar,
.skin-blue .wrapper {
    background-color: @MainOFCColor;
}

/* Mobile view hamburger menu button */
.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: @MainOFCColor;
}

/* Sidebar background highlight (no hover)  */
.skin-blue .sidebar-menu > li > .treeview-menu {
    background-color: @MainOFCColor;
}

/* Sidebar background highlight (hover) */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a,
a:hover {
    background-color: darken(@MainOFCColor, 5%);
    border-left-color: darken(@MainOFCColor, 10%);
}

/* "NAVIGATION" sidebar nav header */
.skin-blue .sidebar-menu > li.header {
    color: @MainOFCTextColor;
    background: darken(@MainOFCColor, 5%);
}

/* User Panel */
.skin-blue .main-header li.user-header {
    background-color: @MainOFCColor !important;
    .sign-out-button {
        background-color: white;
        color: @MainOFCTextColor;
        border-color: @MainOFCColor;
    }
    p {
        color: @MainOFCTextColor !important;
    }
}

/* Sidebar text */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li.active > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li > mt-nav-menu-item > a:hover,
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a,
.skin-blue .sidebar a,
.navbar-toggle {
    color: @MainOFCTextColor;
}

.btn-fab-lg,
.btn-fab-md,
.btn-fab-sm {
    background-color: @MainOFCColor !important;
    border-color: @MainOFCColor !important;
}

.btn-primary,
.btn-success {
    background-color: @MainOFCColor !important;
    border-color: @MainOFCColor !important;
}

.btn-primary:active,
.btn-primary:hover {
    background-color: darken(@MainOFCColor, 5%) !important;
}

.btn-primary-inverse.active,
.btn-primary-inverse.active:hover,
.btn-primary-inverse.focus.active,
.btn-primary-inverse.focus.active:hover,
.btn-primary-inverse:active:focus,
.btn-primary-inverse:active:hover,
.btn-primary-inverse:hover:not([disabled]) {
    background-color: darken(@MainOFCColor, 5%) !important;
}

.mat-mdc-dialog-container {
    position: fixed !important; /* Force the dialog to be fixed */
    top: 50% !important; /* Center vertically */
    left: 50% !important; /* Center horizontally */
    transform: translate(-50%, -50%) !important; /* Center the dialog */
    max-height: 50vh !important; /* Limit height to viewport */
    max-width: 70vh !important; /* Limit height to viewport */
}

.mat-mdc-dialog-surface {
    overflow-y: hidden !important; /* Disable vertical scrolling */
}

.main-header .logo .logo-img {
    padding: 0;
}
